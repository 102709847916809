import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import CarePlanHighlights from "components/HomePage/CaregiverHome/CarePlanHighlights/CarePlanHighlights";
import "components/HomePage/CaregiverHome/CaregiverHome.css";
import { News } from "components/HomePage/CaregiverHome/News";
import UpcomingActivities from "components/HomePage/CaregiverHome/UpcomingActivities/UpcomingActivities";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";

export function CaregiverHome() {
  useAlfredPageSideBar("homePage");
  // Queries

  return (
    <PageContainer>
      <PageMainHeader text="Home" />
      <div className="w-full  max-w-[976px] flex flex-col mt-4  pb-20">
        <div className="flex flex-col">
          <UpcomingActivities />
        </div>
        <div className="flex flex-col gap-8">
          <CarePlanHighlights />
          <News />
        </div>
      </div>
    </PageContainer>
  );
}