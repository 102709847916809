import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import { useMutateChatGptMessages } from "backend/resources/chatGptMessage";
import AlfredIcon from "components/Alfred/Alfred";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CollapseButton } from "components/ChatGptSideBar/ChatGptSideBar";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";
import { useWindowSize } from "hooks/useWindowSize";
import { CareCentralRoute, CarePilotRoute, useAppNavigate } from "lib/routing";
import { ChatMessageType, useGptStore } from "state/gpt";
import { useUserStore } from "state/user";

export function SideBarHomePage() {
  const { isMobile } = useWindowSize();
  useAlfredPageSideBar("homePage");

  return (
    <div className="flex flex-col max-h-full h-full">
      <div className="py-3 px-2">{!isMobile && <CollapseButton />}</div>
      <MessagesThread
        threadId={"homePage"}
        pageType="homePage">
        <InitialContent />
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  const authUser = useUserStore((state) => state.user);
  const navigate = useAppNavigate();
  const conversationId = useGptStore((state) => state.conversationId);
  const chatGptMessagesMutation = useMutateChatGptMessages();
  const setSendToGptRequest = useGptStore((state) => state.setSendToGptRequest);
  const setSidebarType = useGptStore((state) => state.setType);
  const setIsOpen = useGptStore((state) => state.setIsOpen);
  const { isMobile } = useWindowSize();

  return (
    <div className="flex min-w-0 gap-3 pb-6">
      <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
      {/* text and action buttons */}
      <div className="flex flex-col gap-6 bg-gray-50 pt-4 text-sm px-4 py-2 rounded-md">
        {/* intro PP */}
        <p>
          My name is Alfred and I’m your digital
          <span className="font-medium"> Care Navigator. </span>
          If you need help at any time, just click on me on the side of the page
          and I’ll be right here to help you.
        </p>
        <p>
          This is your Home, where you can see your upcoming tasks and the latest news.
        </p>
        {/* action list PP */}
        <p>To get you started, here are some common things I can help with:</p>
        {/* action buttons */}
        <div className="flex flex-col gap-3">
          <ButtonWithIcon
            onClick={async () => {
              if (isMobile) setIsOpen(false);
              navigate({ path: CarePilotRoute.CARE_PLAN });
            }}
            disabled={false}
            text={"View the coaching plan"}
            size={"small"}
            icon={IconOption.ARROW}
          />
          <ButtonWithIcon
            onClick={async () => {
              if (isMobile) setIsOpen(false);
              navigate({
                path: CareCentralRoute.NEW_TASK,
              });
            }}
            disabled={false}
            text={"Create a task"}
            size={"small"}
            icon={IconOption.ARROW}
          />
          <ButtonWithIcon
            onClick={async () => {
              const userMessage = "What is Music Therapy?";
              if (conversationId) {
                const persistedMessage =
                  await chatGptMessagesMutation.mutateAsync({
                    message: {
                      chat_gpt_conversation_id: conversationId,
                      content: userMessage,
                      role: "user",
                      send_to_chat_gpt: true,
                      user_id: authUser?.id,
                    },
                    threadId: "homePage",
                  });
                // if user message was persisted, send to GPT and handle required UI changes
                if (persistedMessage && persistedMessage.length > 0) {
                  setSendToGptRequest({
                    conversationId,
                    text: persistedMessage[0].content,
                    message_type: ChatMessageType.USER_MESSAGE,
                  });
                }
              }
            }}
            disabled={false}
            text={"Ask a question"}
            size={"small"}
            icon={IconOption.ARROW}
          />
        </div>
        <p>
          Or you can just type in what you want to know and I'll try to help.
        </p>
      </div>
    </div>
  );
}
